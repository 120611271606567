import { ApolloClient, createHttpLink, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { appConfig } from './appConfig';

export const graphqlURL = createHttpLink({ uri: appConfig.graphQL });

export const client = new ApolloClient({
  link: graphqlURL,
  cache: new InMemoryCache(),
});

export function setGraphQLToken(token: string) {
  if (client) {
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });
    client.setLink(authLink.concat(graphqlURL));
  }
}

export const ME_QUERY = gql`
query ME_QUERY {
  me {
    firstName
    lastName
    email
  }
}
`;

export const REGISTER_USER_MUTATION = gql`
mutation REGISTER_USER_MUTATION($input: RegisterUserInput) {
  registerUser(input: $input) {
    ... on RegisterUserFailure {
      error {
        ... on EmailAlreadyRegisteredError {
          email
          message
          title
        }
        ... on NotAuthenticatedError {
          message
          title
        }
      }
    }
    ... on RegisterUserSuccess {
      user {
        firstName
        lastName
      }
    }
  }
}
`;
