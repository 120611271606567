import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { auth } from '../code/firebase';

export default function ShareLogout() {
  const { referralCode } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    auth.signOut().finally(() => {
      navigate('../referral/' + referralCode);
    });
  }, []);

  return <div />;
}
