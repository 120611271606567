import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SquareLoader } from 'react-spinners';
import { Button, TextField } from '@mui/material';
import { sendMagicLink } from '../code/firebase';
import './ShareLink.scss';
import './EmailLogin.scss';

export default function EmailLogin() {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { referralCode } = useParams();
  async function sendEmail() {
    if (!referralCode) return;
    try {
      setLoading(true);
      await sendMagicLink(email, referralCode);
      setEmailSent(true);
    } catch (error) {
      setShowError(true);
    } finally {
      setLoading(false);
    }
  }

  let content;

  if (loading) {
    content = (
      <div className="share-link-loading">
        <SquareLoader size={150} color="#FFF" />
      </div>
    );
  } else if (!emailSent && !showError) {
    content = (
      <div className="email-login-input">
        <p>Sign in and start investing today</p>
        <TextField fullWidth label="Email" size="small" onChange={(e) => setEmail(e.target.value)} />
        <Button fullWidth variant="contained" size="medium" onClick={sendEmail} disabled={loading}>Send Verification Email</Button>
      </div>
    );
  } else if (showError) {
    content = (
      <>
        <p>The was an error sending an email to: {email}. Please Retry</p>
        <Button fullWidth size="medium" onClick={(() => setShowError(false))}>Retry</Button>
      </>
    );
  } else if (emailSent) {
    content = (
      <div className="email-sent-message">
        <p>A magic link has been sent to </p>
        <p className="email">{email}</p>
        <p>Please check your inbox to proceed </p>
        <Button fullWidth variant="outlined" size="medium" onClick={sendEmail} disabled={loading}>Resend Email</Button>
      </div>
    );
  }
  return (
    <div className="email-login-container">
      {content}
    </div>
  );
}
