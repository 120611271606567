import './ShareLink.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SquareLoader } from 'react-spinners';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, signInGoogle, signInApple } from '../code/firebase';
import ShareLinkRegister from './ShareLinkRegister';
import { setGraphQLToken } from '../code/graphql';
import EmailLogin from './EmailLogin';

export default function ShareLink() {
  const [user, userLoading, userError] = useAuthState(auth);
  const [userInitialized, setUserInitialized] = useState(false);
  const [insecure, setInsecure] = useState(false);
  const [emailLogin, setEmailLogin] = useState(false);
  const { referralCode } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userLoading) {
      if (userError) {
        console.log('AUTH ERROR');
        console.error(userError);
      }
      if (!user) {
        setUserInitialized(false);
        setGraphQLToken('');
      } else {
        user.getIdToken().then(token => {
          setGraphQLToken(token);
          setUserInitialized(true);
        });
      }
    }
  }, [user, userLoading, userError]);

  function retryClick() {
    navigate('../share/' + referralCode);
  }

  async function googleClicked() {
    const ua = navigator.userAgent;
    if (ua.includes('FBAN') || ua.includes('FBAV') || ua.includes('Instagram')) {
      setInsecure(true);
    } else {
      await signInGoogle();
    }
  }

  function emailClicked() {
    setEmailLogin(true);
  }

  let content = (
    <div className="share-link-inner">
      <h2 className="share-link-header">Grow Your Crypto</h2>
      <p className="share-link-description">Giddy is the easiest way to start investing in DeFi. Join the new world of money and start earning today!</p>
      <div className="social-share-box">
        <h4 className="share-link-join">Sign In to Join the Waitlist</h4>
        <button className="login-button" type="button" onClick={googleClicked}>
          <img src="../icon-google.png" alt="Google" width={26} />
          <span className="login-text">Sign in with Google</span>
        </button>
        <button className="login-button" type="button" onClick={signInApple}>
          <img src="../icon-apple.png" alt="Apple" width={26} />
          <span className="login-text">Sign in with Apple</span>
        </button>
        <button className="login-button" type="button" onClick={emailClicked}>
          <img src="../email-icon.svg" alt="Email" width={26} />
          <span className="login-text">Sign in with Email</span>
        </button>
      </div>
    </div>
  );

  if (insecure) {
    content = (
      <div className="share-link-inner">
        <h2 className="share-link-header">Insecure Browser</h2>
        <div className="social-share-box">
          <p className="share-link-error">Giddy requires you to use a secure browser. Please copy the share link and use it in your device&#39;s native browser such as Chrome or Safari.</p>
          <h4 className="url-header">app.giddy.co/share/{referralCode}</h4>
        </div>
      </div>
    );
  } else if (userLoading) {
    content = (
      <div className="share-link-loading">
        <SquareLoader size={150} color="#FFF" />
      </div>
    );
  } else if (userError) {
    content = (
      <div className="share-link-inner">
        <div className="social-share-box">
          <h4 className="download-header">We’re Sorry</h4>
          <p className="share-link-error">There is an error logging you into this application.</p>
          <p className="share-link-error">{userError.message}</p>
          <button className="retry-button" onClick={retryClick}>Retry</button>
        </div>
      </div>
    );
  } else if (user && userInitialized) {
    content = <ShareLinkRegister />;
  } else if (emailLogin) {
    content = <EmailLogin />;
  }

  return (
    <div className="share-link">
      <img className="share-link-logo" src="../giddy-logo-03.svg" alt="Giddy Logo" />
      {content}
    </div>
  );
}
