import { initializeApp } from 'firebase/app';
import { getAuth, OAuthProvider, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, signInWithRedirect, setPersistence, inMemoryPersistence, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { appConfig, isDev } from './appConfig';

const firebaseApp = initializeApp(appConfig.firebase);
export const auth = getAuth(firebaseApp);

export async function signInGoogle() {
  try {
    await setPersistence(auth, inMemoryPersistence);
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.setCustomParameters({
      disallow_webview: 'false',
    });

    await signInWithRedirect(auth, provider);
  } catch (err) {
    console.error('firebase.signInWithRedirect', err);
  }
}

export async function signInFacebook() {
  try {
    const provider = new FacebookAuthProvider();
    provider.addScope('user_birthday');

    await signInWithRedirect(auth, provider);
  } catch (err) {
    console.error('firebase.signInFacebook', err);
  }
}

export async function signInApple() {
  try {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    await signInWithRedirect(auth, provider);
  } catch (err) {
    console.error('firebase.signInApple', err);
  }
}

export async function signInTwitter() {
  try {
    const provider = new TwitterAuthProvider();
    await signInWithRedirect(auth, provider);
  } catch (err) {
    console.error('firebase.signInTwitter', err);
  }
}

export async function sendMagicLink(email: string, referralCode: string) {
  const firebaseAuth = getAuth(firebaseApp);
  const actionCodeSettings = {
    url: `${appConfig.magicLinkDomain}/verify-email/${referralCode}`,
    handleCodeInApp: true,
    iOS: {
      bundleId: `com.getdefiq.giddy${isDev ? '.dev' : ''}`,
    },
    android: {
      packageName: `com.getdefiq.giddy${isDev ? '.dev' : ''}`,
      installApp: true,
    },
    dynamicLinkDomain: appConfig.dynamicLinkDomain,
  };

  try {
    await sendSignInLinkToEmail(firebaseAuth, email, actionCodeSettings);
    window.localStorage.setItem('emailSignIn', JSON.stringify(email));
  } catch (error) {
    console.error('firebase.sendMagicLink', error);
  }
}

export async function signInWithEmail(email: string, url: string = window.location.href) {
  if (!email) {
    throw new Error('No email provided');
  }

  if (isSignInWithEmailLink(auth, url)) {
    await signInWithEmailLink(auth, email, url);
  } else {
    throw new Error('Invalid sign in link');
  }
}
