import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { SquareLoader } from 'react-spinners';
import { Button, TextField } from '@mui/material';
import { auth, signInWithEmail } from '../code/firebase';
import { setGraphQLToken } from '../code/graphql';
import useLocalStorage from '../code/hooks/useLocalStorage';
import ShareLinkRegister from './ShareLinkRegister';
import './ShareLink.scss';
import './EmailLogin.scss';

export default function VerifyEmail() {
  const [user, userLoading, userError] = useAuthState(auth);
  const [localStorageEmail] = useLocalStorage<string>('emailSignIn', '');
  const [email, setEmail] = useState<string>('');
  const { referralCode } = useParams();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function verifyEmail() {
    setLoading(true);
    try {
      await signInWithEmail(email, window.location.href);
    } catch (error) {
      setShowError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!userError) {
      if (!email && localStorageEmail && !user && !userLoading) {
        setEmail(localStorageEmail);
      }

      if (email && localStorageEmail && !user && !userLoading) {
        verifyEmail();
      }

      if (user) {
        user.getIdToken().then(token => {
          setGraphQLToken(token);
          setLoggedIn(true);
        });
      }
    }
  }, [email, localStorageEmail, loggedIn, user, userLoading, userError]);

  let content;

  if (showError) {
    content = (
      <>
        <div>We were unable to complete the login for {email}. </div>
        <a href={`/share/${referralCode}`}>Click here</a> to try again.
      </>
    );
  } else if (userLoading || loading) {
    content = (
      <div className="share-link-loading">
        <SquareLoader size={150} color="#FFF" />
      </div>
    );
  } else if (!userLoading && !loggedIn && !localStorageEmail) {
    content = (
      <div className="email-login-input">
        <p>It appears you started the sign up process from a different device. Please re-enter your email to complete the registration.</p>
        <TextField fullWidth label="Email" size="small" onChange={(e) => setEmail(e.target.value)} />
        <Button fullWidth variant="contained" size="medium" onClick={verifyEmail} disabled={userLoading || loading}>Verify Email</Button>
      </div>
    );
  } else if (user && loggedIn) {
    content = <ShareLinkRegister />;
  }

  return (
    <div className="share-link">
      <img className="share-link-logo" src={`${process.env.PUBLIC_URL}/giddy-logo-03.svg`} alt="Giddy Logo" />
      {content}
    </div>
  );
}
