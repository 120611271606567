import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#282828',
    },
    secondary: {
      main: '#FFF',
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    button: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: 16,
      letterSpacing: '0.08em',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          padding: '6px 18px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: '#FFF',
          borderRadius: '4px',
        },
      },
    },
  },
});
