import React, { useEffect } from 'react';
import { SquareLoader } from 'react-spinners';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { REGISTER_USER_MUTATION } from '../code/graphql';
import './ShareLink.scss';
import './ShareLinkRegister.scss';
import { appConfig, stagingConfig } from '../code/appConfig';

export default function ShareLinkRegister() {
  const { referralCode } = useParams();
  const [registerUserFunction, registerUserMutation] = useMutation(REGISTER_USER_MUTATION);
  const navigate = useNavigate();

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        if (appConfig.graphQL === stagingConfig.graphQL && (error.message === 'Failed to fetch' || error.message === 'Load failed')) {
          console.log('ShareLinkRegister.graphQLError: Not behind VPN on Staging');
        } else {
          console.error('ShareLinkRegister.graphQLError', error);
        }
      });
    }
    if (networkError) {
      console.error('ShareLinkRegister.networkError', networkError);
    }
  });

  useEffect(() => {
    try {
      console.log('registering user: ' + referralCode);
      registerUserFunction({
        variables: {
          input: {
            referralCode,
          },
          onerror: errorLink,
        },
      });
    } catch (error: any) {
      console.error('ShareLinkRegister.registerUser', error);
    }
  }, []);

  useEffect(() => {
    if (registerUserMutation.called && !registerUserMutation.loading) {
      if (registerUserMutation.data) {
        console.log('registered: ' + JSON.stringify(registerUserMutation.data));
      }
    }
  }, [registerUserMutation]);

  function retryClick() {
    navigate('../share/' + referralCode);
  }

  if (!registerUserMutation.called || registerUserMutation.loading) {
    return (
      <div className="share-link-loading">
        <SquareLoader size={150} color="#FFF" />
      </div>
    );
  }

  if (registerUserMutation.error || registerUserMutation.data.registerUser.error) {
    return (
      <div className="share-link-inner">
        <div className="social-share-box">
          <h4 className="download-header">We’re Sorry</h4>
          <p className="share-link-error">Something went wrong during the registration process.</p>
          <button className="retry-button" onClick={retryClick}>Retry</button>
        </div>
      </div>
    );
  }

  return (
    <div className="share-link-inner">
      <h2 className="share-link-header">You’re almost there!!</h2>
      <div className="social-share-box">
        <h4 className="download-header">Download the Giddy mobile app to complete your account</h4>
        <img className="qr-code" src={`${process.env.PUBLIC_URL}/app-qrcode.svg`} alt="Giddy App Download" />
        <div className="download-box">
          <a href="https://apps.apple.com/us/app/keynote/id1596780905">
            <img className="download-image" src={`${process.env.PUBLIC_URL}/icon-get-apple.png`} alt="Download on the App Store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.getdefiq.giddy">
            <img className="download-image" src={`${process.env.PUBLIC_URL}/icon-get-google.png`} alt="Get it on Google Play" />
          </a>
        </div>
      </div>
    </div>
  );
}
