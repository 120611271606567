import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { client } from './code/graphql';
import ShareLink from './components/ShareLink';
import App from './components/App';
import ShareLogout from './components/ShareLogout';
import VerifyEmail from './components/VerifyEmail';
import GiddyTheme from './code/GiddyTheme';

Sentry.init({
  dsn: 'https://5d85fb9766aa4ab79467691fcf1116b0@o1086015.ingest.sentry.io/4504317496393728',
  integrations: [new BrowserTracing(), new CaptureConsole({ levels: ['error'] })],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={GiddyTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/share/:referralCode" element={<ShareLogout />} />
          <Route path="/referral/:referralCode" element={<ShareLink />} />
          <Route path="/verify-email/:referralCode" element={<VerifyEmail />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);
