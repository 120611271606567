export const isDev: boolean = process.env.NODE_ENV !== 'production';

export const prodConfig = {
  graphQL: 'https://api.giddy.co/g',
  firebase: {
    apiKey: 'AIzaSyC1ueCmWcIVfQwPCmqrmA--5H8usp-S7kU',
    authDomain: 'defiq-prod.firebaseapp.com',
    projectId: 'defiq-prod',
    storageBucket: 'defiq-prod.appspot.com',
    messagingSenderId: '1061370467915',
    appId: '1:1061370467915:web:d7cdf981658e832f189fce',
    measurementId: 'G-YN7MT37S67',
  },
  dynamicLinkDomain: 'm.giddy.co',
  magicLinkDomain: 'https://app.giddy.co',
};

export const stagingConfig = {
  graphQL: 'https://api.giddystaging.com/g',
  firebase: {
    apiKey: 'AIzaSyCfAHvVKEEL2hFqKrRIYWeKHmlhpkgIs3Y',
    authDomain: 'defiq-a98c1.firebaseapp.com',
    projectId: 'defiq-a98c1',
    storageBucket: 'defiq-a98c1.appspot.com',
    messagingSenderId: '1019504651311',
    appId: '1:1019504651311:web:270b70f9be4ff9bbd76ada',
  },
  dynamicLinkDomain: 'm2.giddystaging.com',
  magicLinkDomain: `${isDev ? 'http://localhost:3000' : 'https://app.giddystaging.com'}`,
};

export const mvpConfig = {
  graphQL: 'https://api.defiq.finance/g',
  firebase: {
    apiKey: 'AIzaSyCfAHvVKEEL2hFqKrRIYWeKHmlhpkgIs3Y',
    authDomain: 'defiq-a98c1.firebaseapp.com',
    projectId: 'defiq-a98c1',
    storageBucket: 'defiq-a98c1.appspot.com',
    messagingSenderId: '1019504651311',
    appId: '1:1019504651311:web:270b70f9be4ff9bbd76ada',
  },
};

export const localConfig = {
  graphQL: 'http://localhost:8080/g',
  firebase: {
    apiKey: 'AIzaSyCfAHvVKEEL2hFqKrRIYWeKHmlhpkgIs3Y',
    authDomain: 'defiq-a98c1.firebaseapp.com',
    projectId: 'defiq-a98c1',
    storageBucket: 'defiq-a98c1.appspot.com',
    messagingSenderId: '1019504651311',
    appId: '1:1019504651311:web:270b70f9be4ff9bbd76ada',
  },
  dynamicLinkDomain: 'm2.giddystaging.com',
  magicLinkDomain: 'http://localhost:3000',
};

export const appConfig = prodConfig;
